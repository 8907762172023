canopyPublic.controller("RegisterController", ["$scope", "$rootScope", "$state", "LumaPublicAPI", "canopyModals", "canopyDebug", function ($scope, $rootScope, $state, LumaPublicAPI, canopyModals, canopyDebug) {

	var init = function () {
		$scope.application.keycloakEnabled = Luma.keycloakEnabled;
		$scope.isDebug = canopyDebug.getIsDebugMode();
		$scope.groupTypes = [];
		$scope.selectableGroups = [];
		$scope.ancillary = {};
		$scope.registration = {
			email: "",
			password: "",
		};

		var userInfo = JSON.parse(window.localStorage.getItem("deletedUserInfo") || "null");

		if (Luma && Luma.samlResponse && Luma.samlResponse["saml.registrationProperties"]) {
			$scope.saml = {
				"registrationProperties": Luma.samlResponse["saml.registrationProperties"]
			};

			if (!userInfo) {
				userInfo = {};
			}

			userInfo.email =    Luma.samlResponse["saml.registrationProperties"].email || "";
			userInfo.forename = Luma.samlResponse["saml.registrationProperties"].forename || "";
			userInfo.surname =  Luma.samlResponse["saml.registrationProperties"].surname || "";
		}

		if (userInfo ) {
			$scope.registration.forename = userInfo.forename || "";
			$scope.registration.surname = userInfo.surname || "";
			$scope.registration.email = userInfo.email || "";
		}

		if (Luma.keycloakEnabled) {
			try {
				var encodedRegisterDetails = new URLSearchParams(window.location.search).get("registerDetails");
				if (encodedRegisterDetails) {
					var registerDetails = JSON.parse(atob(encodedRegisterDetails));
					$scope.registration.forename = registerDetails.forename;
					$scope.registration.surname = registerDetails.surname;
					$scope.registration.email = registerDetails.email;
				}
			} catch (error) {
				console.error(error);
			}
		}

		initDomains();

		/* Listen for seletion-made event on checkboxes or <select> element 
		passed up from child component canopyRegistrationComponent
		Ensure 'data' is correctly handled upstream before being set into state here */
		$scope.$on("selection-made", function(event, data) {
			$scope.ancillary.groups.primary = data.primaryGroup;
			$scope.ancillary.groups.available = data.selectableGroups;
		});
	};
	
	var initDomains = function () {
		var onSuccess = function (response) {
			$scope.ancillary.domains = {
				available: response.data,
				selected: null
			};
		};
		
		var onError = function () {
		};
		
		LumaPublicAPI.listRegistrationDomains().then(onSuccess, onError);
	};
	
	var initGroups = function () {
		var onSuccess = function (response) {
			$scope.ancillary.groups = {
				available: response.data,
				selected: [],
				primary: null
			};
			
			$rootScope.$broadcast("changed-domain", {
				selectableGroups: $scope.ancillary.groups,
			});
		};
		var onError = function () {};

		var domain = $scope.ancillary.domains.selected;

		var domainID = domain.id;
		LumaPublicAPI.listRegistrationGroups(domainID).then(onSuccess, onError);

	};

	function deleteUserLocalStorage() {
		delete window.localStorage["deletedUserInfo"];
	}
	
	$scope.onGroupSelected = function () {
		$scope.ancillary.groups.selected = $scope.ancillary.groups.available.filter(function (group) {
			return group.isSelected;
		});
	};
	
	init();
	
	$scope.onSubmit = function () {
		$scope.$broadcast("submit");
	};

	$scope.submitRegistration = function () {
		if ($scope.isSubmitting) {
			return;
		}
		
		$scope.isSubmitting = true;

		if ($scope.ancillary.domains.selected) {
			$scope.registration.domainID = $scope.ancillary.domains.selected.id;
		}
		
		$scope.registration.groupIDs       = null;
		$scope.registration.primaryGroupID = null;
		
		var selectedGroups = $scope.ancillary.groups.available.filter(function (group) { 
			return group.isSelected;
		});
		
		$scope.registration.groupIDs = selectedGroups.map(function (group) {
			return group.uuid;
		}).toString();
		

		if ($scope.ancillary.groups.primary) {
			$scope.registration.primaryGroupID = $scope.ancillary.groups.primary.uuid;
		}
		
		if (selectedGroups.length && !$scope.registration.primaryGroupID) {
			var modal = canopyModals.definitions.message({
				header: "No primary group",
				body: "Please select a primary group"
			});
		
			canopyModals.instantiate(modal);
			
			$scope.isSubmitting = false;

			return;
		}
		
		var onSuccess = function (response) {		
			var modal = canopyModals.definitions.message({
				header: "Success",
				body: "Your registration was successful. A confirmation email will be sent to the email address you used to register. For reference, your unique registration ID is: " + response.data + "."
			});
			
			$scope.isSubmitting = false;
		
			canopyModals.instantiate(modal).result.then(function () {
				deleteUserLocalStorage();
				$state.go("public.login");
			});
		};
		
		var onError = function (response) {
			var modal = canopyModals.definitions.serverError(response.data);
			canopyModals.instantiate(modal);
			
			$scope.isSubmitting = false;
		};
		
		if (Luma.keycloakEnabled) {
			LumaPublicAPI.register($scope.registration).then(
				function() {
					deleteUserLocalStorage();
					$state.go("public.register-complete");
				}, 
				onError);
		} else {
			LumaPublicAPI.register($scope.registration).then(onSuccess, onError);
		}
	};
	$scope.$watch("ancillary.domains.selected", function (domain) {
		if (domain) {
			initGroups();
		}
	});
}]);