"use strict";

canopyPublic.config(["$stateProvider", "$urlRouterProvider", function ($stateProvider, $urlRouterProvider) {
	$stateProvider.state("public.login", {
		url: "*path",
		controller: "LoginController",
		templateUrl: Luma.paths.context + "/system/mantle/components/canopyPublic/states/login/login.template.html",
		onEnter: function() {
			var isAppRoot = window.location.pathname === Luma.paths.context + "/";
			if (Luma.user && isAppRoot) {
				window.location = Luma.paths.context + "/content/site/home.jsp";
			}
		}
	});
}]);

canopyPublic.run(["$transitions", function ($transitions) {
	// Remove the requirement to pass a "path" argument when transitioning to the wildcard login state.
	$transitions.onBefore({}, function (transition) {
		var targetState = transition.targetState();
		
		var name       = targetState.name();
		var parameters = targetState.params();
		
		if (name === "public.login" && parameters.path === undefined) {
			return transition.router.stateService.target("public.login", {
				path: ""
			});
		}
	});
}]);