"use strict";

canopyPublic.config(["$stateProvider", "$urlRouterProvider", function ($stateProvider, $urlRouterProvider) {
	$stateProvider.state("public.denied", {
		url: "/denied",
		controller: "DeniedController",
		templateUrl: Luma.paths.context + "/system/mantle/components/canopyPublic/states/denied/denied.template.html"
	});

	$stateProvider.state("public.denied.UserUnauthorized", {
		url: "/UserUnauthorized",
		controller: "DeniedController",
		templateUrl: Luma.paths.context + "/system/mantle/components/canopyPublic/states/denied/denied.template.html"
	});

	$stateProvider.state("public.denied.UserDisabled", {
		url: "/UserDisabled",
		controller: "DeniedController",
		templateUrl: Luma.paths.context + "/system/mantle/components/canopyPublic/states/denied/denied.template.html"
	});

	$stateProvider.state("public.denied.UserLocked", {
		url: "/UserLocked",
		controller: "DeniedController",
		templateUrl: Luma.paths.context + "/system/mantle/components/canopyPublic/states/denied/denied.template.html"
	});

	$stateProvider.state("public.denied.other", {
		url: "/:code",
		params: {
			message: {
				dynamic: true,
				squash: true,
				value: null
			}
		},
		controller: "DeniedController",
		templateUrl: Luma.paths.context + "/system/mantle/components/canopyPublic/states/denied/denied.template.html"
	});

}]);
