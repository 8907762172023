canopyPublic.controller("ResetRequestController", ["$scope", "$state", "LumaPublicAPI", "canopyModals", function ($scope, $state, LumaPublicAPI, canopyModals) {
	var init = function () {
		$scope.reset = {
			email: ""
		};
	};
	
	$scope.onSubmit = function () {
		$scope.$broadcast("submit");
	};
	
	$scope.submitReset = function () {
		var onSuccess = function (response) {
			var modal = canopyModals.definitions.message({
				header: "Password reset",
				body: "An email has been sent to you containing further password reset instructions.",
				confirm: "Return to login"
			});
			
			canopyModals.instantiate(modal).result.then(function () {
				$state.go("public.login");
			});
		};
		
		var onError = function (response) {
			var modal = canopyModals.definitions.serverError(response.data);
			
			if (response.data && response.data.reasons) {
				var emailError = response.data.reasons.find(function (reason) {
					return reason.key === "email";
				});
				
				if (emailError) {
					modal = canopyModals.definitions.message({
						header: "Error",
						body: "That email address does not match any in the system.",
						confirm: "OK"
					});
				}
			}
			
			canopyModals.instantiate(modal);
		};
		
		LumaPublicAPI.requestUserPasswordReset($scope.reset).then(onSuccess, onError);
	};
		
	init();
}]);