"use strict";

canopyPublic.config(["$stateProvider", "$urlRouterProvider", function ($stateProvider, $urlRouterProvider) {	
	$stateProvider.state("public.reset", {
		url: "/reset?token",
		params: {
			code:null
		},
		controller: "ResetController",
		templateUrl: Luma.paths.context + "/system/mantle/components/canopyPublic/states/reset/reset.template.html"
	});
}]);
