/* eslint-disable no-redeclare */
"use strict";

var canopyPublic = angular.module(
	"canopyPublic",
	[
		"ui.router",
		"ui.bootstrap",
		"ngAnimate",
		"ngSanitize",
		"canopyCore",
		"canopyRegistrationGroupSelectorModule"
	]);

canopyPublic.controller("ApplicationController", ["$scope", "$timeout", "$location", "$state", "$stateParams", "$http", function ($scope, $timeout, $location, $state, $stateParams, $http) {
	$scope.application = {
		paths: Luma.paths,
		host: $location.host()
	};
}]);

canopyPublic.run(["$state", "$stateParams", function ($state, $stateParams) {
	// This solves page refresh and getting back to state
}]);

canopyPublic.config(["$locationProvider", function ($locationProvider) {
	$locationProvider.html5Mode(false);
	$locationProvider.hashPrefix("!");
}]);

canopyPublic.config(["$stateProvider", "$urlRouterProvider", function ($stateProvider, $urlRouterProvider) {
	$stateProvider.state("public", {
		url: "",
		"abstract": true,
		templateUrl: Luma.paths.context + "/system/mantle/components/canopyPublic/states/canopy/canopy.template.html"
	});
}]);

canopyPublic.config(["$httpProvider", function ($httpProvider) {  
	$httpProvider.interceptors.push("errorInterceptor");
}]);

/* ==================== */
/* 	 INTERCEPTORS       */
/* ==================== */

canopyPublic.factory("errorInterceptor", ["$window", "$q", "$injector", function ($window, $q, $injector) {  
	return {
		responseError: function (responseError) {
			return $q.reject(responseError);
		}
	};
}]);
