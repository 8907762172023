canopyPublic.controller("DeniedController", ["$scope", "$state", "$stateParams", "$location", function ($scope, $state, $stateParams, $location) {
	var init = function () {

		$scope.application = {
			protocol: $location.protocol(),
			host: $location.host(),
			paths: Luma.paths
		};

		var path = $scope.application.paths.context + "/servlet/hosted/sso/denied/";

		$scope.templateSrc = null;

		var defaultMsg = "No further details available at this time...";
		$scope.msg = defaultMsg;

		if ($stateParams) {
			// eslint-disable-next-line no-console
			console.log("DENIED: state params: ", $stateParams);
			// eslint-disable-next-line no-console
			console.log("DENIED: state code: ", $stateParams.code);
			// eslint-disable-next-line no-console
			console.log("DENIED: state message: ", $stateParams.message);
		}

		if ($state.current.name === "public.denied") {
			// empty
		} else if ($state.current.name === "public.denied.UserUnauthorized") {
			$scope.msg = "User unauthorized";
			$scope.templateSrc = path + "UserUnauthorized.html";
		} else if ($state.current.name === "public.denied.UserDisabled") {
			$scope.msg = "User disabled";
			$scope.templateSrc = path + "UserDisabled.html";
		} else if ($state.current.name === "public.denied.UserLocked") {
			$scope.msg = "User locked";
			$scope.templateSrc = path + "UserLocked.html";
		} else if ($state.current.name === "public.denied.other") {
			if ($stateParams && $stateParams.code && $stateParams.message) {
				$scope.msg = $stateParams.message || defaultMsg;
			}
		}
	};

	init();
}]);