canopyPublic.controller("ConfirmController", ["$scope", "$stateParams", "LumaPublicAPI", function ($scope, $stateParams, LumaPublicAPI) {
	$scope.regConfirmed = false;
	var init = function () {
		var onSuccess = function (response) {
			$scope.regConfirmed = true;
		};
		var onError = function () {};
        
		var data = {
			uuid: $stateParams.uuid
		};
		LumaPublicAPI.confirmRegistration(data).then(onSuccess, onError);
	};
	init();
}]);