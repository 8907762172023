declare var Luma;
declare var angular;

/**
 * Interfaces to be eventually imported with a bundling system rather
 * than declared on a component basis.
 */
declare interface IGroup {
	name: string;
	uuid: string;
	type: string;
	id: number;
	$$hashKey?: string;
	isDisabled?: boolean;
	isSelected?: boolean;
	isEditable?: boolean;
	available?: boolean;
}

/**
	 * Ensure this object adheres to the proper component schema
	 * as it's just an object at this point, but will be used in angular.component
	 * as the definition of a component; expected to match the Component structure.
*/
let canopyRegistrationComponent: any = {
templateUrl: `${Luma.paths.context}/system/mantle/components/canopyPublic/components/registration/canopy-registration-group-selector.template.html`,
	bindings: {
		groupTypesHidden: "@",
		groupTypesForceSingleSelection: "@",
		groupTypesAutoSelectIfSingle: "@",
		groupsAutoAssignment: "@",
		primaryGroupCandidates: "@",
		primaryGroupAutoSelect: "@",
		primaryGroupHideSelection: "@",
		primaryGroupAutoAssign: "@"
	},
	controllerAs: '$ctrl',
	controller: ['$scope', '$rootScope', class RegistrationController {
		state: any; // State of this
		parentScope: any; // Registration component
		scope: any;
		rootScope: any;
		updateData: any;
		groups: any;
		selectableGroups: IGroup[] = [];
		options: any;
		groupValues: any;

		constructor($scope, $rootScope) {
			/* Recast $scope from Parent scope as this.scope */
			this.scope = $scope;
			this.scope.primaryGroup = undefined;
			this.rootScope = $rootScope;
			this.options = {
				groupTypesHidden: [],
				groupTypesAutoSelectIfSingle: [],
				groupsAutoAssignment: [],
				primaryGroupCandidates: [],
				primaryGroupHideSelection: false,
				primaryGroupAutoSelection: false,
				primaryGroupAutoAssign: undefined
			}
		}

		$onInit() {
			/* Detect change in domain drop-down or on first initialisation. Receive data into state. */
			this.scope.$on('changed-domain', (event, data) => {
				/* Set new group data from event into state */
				this.scope.$ctrl.selectableGroups = this.getGroupsCollectionByGroupType(data.selectableGroups.available);
				this.scope.$ctrl.primaryGroups = data.selectableGroups.available;

				/* Run options before primary group settings */
				this.setSingleSelection();
				this.setHiddenGroups();
				this.setAutoSelectedSingleGroupTypes();
				this.setAutoAssignedGroups();
				this.listenForDropdownSelection();

				// /* Set Primary groups */
				this.setPrimaryGroupAutoSelection();
				this.setPrimaryGroupHideSelection();
				this.setPrimaryGroupCandidates();
				this.setPrimaryGroupAutoAssign(data.selectableGroups.available);
			});
		};


		/**
		*  Listen for fieldModel change event on <select> and call addGroupToSelection
		*  $emited from form-field.directive
		*/ 
		listenForDropdownSelection() {
			this.scope.$on('dropdown-selection', (event, data) => {
				const selectedUuid = data.value ? data.value.uuid : '';
				
				if (selectedUuid && data.value.type && !data.isPrimaryGroup) {
					/* Find uuid in our selectable groups groups assign isSelected */
					this.scope.$ctrl.selectableGroups[data.value.type].forEach(item => {
						if (item.uuid === selectedUuid) {
							item.isSelected = true;
						} else {
							item.isSelected = false;
						}
					});
				} else if (!selectedUuid && data.groupType && !data.isPrimaryGroup) {
					// You selected something..
					// * without a UUID
					// * with a group type 
					// * which is not the primary group selector
					// which means you selected the 'please select' option in a standard group dropdown...
					this.scope.$ctrl.selectableGroups[data.groupType].forEach(group => {
						group.isSelected = false;
					});
				} else if (selectedUuid && data.value.type && data.isPrimaryGroup) {
					this.scope.primaryGroup = data.value;
				}

				// If we have a groupType (standard group selection):
				if (data.groupType) {
					this.scope.$ctrl.selectableGroups[data.groupType].forEach((item, index, arr) => {

						// if we have a 'groups-auto-assignment', select the relevant group:
						if (this.options.groupsAutoAssignment.contains(item.uuid)) {
							item.isSelected = true;
						} 

						// if we have a 'group-types-auto-select-if-single' option for this group type make sure we respect that here.
						if (this._isGroupInSingleSelectionOpts(item.type) && arr.length === 1) {
							item.isSelected = true; 
						}
					});
				}

				this.addGroupToSelection();
			});
		}

			/**
			 * Get group types from string
			 * @param {string}
			 */
			_getGroupTypesFromString = (str: string) => str.replace(/ /g, "").split(",").map(type => type.toLowerCase());


			/**
			 * Check if a given Group Type is specified under 'group-types-auto-select-if-single' to check
			 * @param {string}
			 * @return {boolean}
			 */
			_isGroupInSingleSelectionOpts = (type: string) => this.options.groupTypesAutoSelectIfSingle.contains(type.toLowerCase())


			/**
			 * Check if a given UUID has been specified as mandatory
			 * @param {string}
			 * @return {boolean}
			 */
			_isGroupUuidMandatory = (uuid: string) => this.options.groupsAutoAssignment.includes(uuid);

			/**
			 * CONFIG OPTIONS
			 * Functions for setting config for use in template (based on options passed to component)
			 * Each function checks if the attr is set, and returns console info message if omitted
			 * Component attribute options are read and stored on `this.options`
			 */

			setHiddenGroups = () => {
				const propName = 'group-types-hidden';
				if (this.scope.$ctrl.groupTypesHidden === undefined) {
					return console.warn(`No attr ${propName} set. This option is ignored.`);
				}
				this.options.groupTypesHidden = this._getGroupTypesFromString(this.scope.$ctrl.groupTypesHidden);
			}

			/* Select group if only one available */
			setAutoSelectedSingleGroupTypes = () => {
				const propName = 'group-types-auto-select-if-single';
				if (this.scope.$ctrl.groupTypesAutoSelectIfSingle === undefined) {
					return console.warn(`No attr ${propName} set. This option is ignored.`);
				}

				/* Setting passed-in options into component state */
				this.options.groupTypesAutoSelectIfSingle = this._getGroupTypesFromString(this.scope.$ctrl.groupTypesAutoSelectIfSingle);
				/* Compare each group's key to array of group-types nominated for auto-select */
				Object.keys(this.scope.$ctrl.selectableGroups).forEach( (type: string) => {
					/* Check if there is only one Group for this Type */
					const isSingleGroup = this.hasSingleGroup(type);
					/* Check that the type is both specified *and* the only option available
							before marking as isSelected for the template  */
					this.scope.$ctrl.selectableGroups[type].map(group => {
						group.isSelected = ( this.options.groupTypesAutoSelectIfSingle.indexOf(type.toLowerCase()) > -1 && isSingleGroup);
						if (this.options.groupsAutoAssignment.contains(group.uuid)) {
							group.isDisabled = true;
						} else {
							group.isDisabled = false;
						}
					});
				});
			}

			setSingleSelection = () => {
				const propName = 'group-types-force-single-selection';
				if (this.scope.$ctrl.groupTypesForceSingleSelection === undefined) {
					return console.warn(`No attr ${propName} set. This option is ignored.`);
				}
				/* Assign passed-in options to component state */
				this.options.groupTypesForceSingleSelection = this._getGroupTypesFromString(this.scope.$ctrl.groupTypesForceSingleSelection);
			}

			setAutoAssignedGroups = () => {
				const propName = 'groups-auto-assignment';
				if (this.scope.$ctrl.groupsAutoAssignment === undefined) {
					return console.warn(`No attr ${propName} set. Specify UUIDs. This option is ignored.`);
				}

				/* Store UUID options into state as array */
				this.options.groupsAutoAssignment = this._getGroupTypesFromString(this.scope.$ctrl.groupsAutoAssignment);
				/* Check each group for match with a supplied UUID */
				Object.keys(this.scope.$ctrl.selectableGroups).forEach( ( key: string ) => {
					this.scope.$ctrl.selectableGroups[key].forEach( ( group: IGroup ) => {
						let autoSelected = this.options.groupsAutoAssignment.indexOf(group.uuid) > -1;

						if (autoSelected) {
							// Set as isSelected and disabled if matches supplied mandatory UUID
							group.isSelected = autoSelected;
							group.isDisabled = autoSelected;
						}
					})
				});
			}

			/**
			* Primary group candidates.
			* Only happens on the init or domain change.
			*/
			setPrimaryGroupCandidates = () => {
				const propName = 'primary-groups-candidates';
				if (this.scope.$ctrl.primaryGroupCandidates === undefined) {
					return console.warn(`No attr ${propName} set. This option is ignored.`);
				};

				if (this.scope.primaryGroupAutoSelect) {
					return;
				}
				this.options.primaryGroupCandidates = this._getGroupTypesFromString(this.scope.$ctrl.primaryGroupCandidates);

				let allGroups = this._getAvailableGroupsCollection(this.scope.$ctrl.selectableGroups)

				let newPrimaryGroups;

				if (this.options.primaryGroupCandidates.length && this.options.primaryGroupCandidates[0] !== "") {
					
					newPrimaryGroups = allGroups.filter( (group:any) => { 
						return group.isSelected === true && this.options.primaryGroupCandidates.indexOf(group.type.toLowerCase()) > -1 
					});

					this.scope.primaryGroupsType = Object.keys(this.scope.$ctrl.selectableGroups).filter( (group:any) => {
						return this.options.primaryGroupCandidates.indexOf(group.toLowerCase()) > -1;
					});

				} else {
					newPrimaryGroups = allGroups.filter( (group:any) => { 
						return group.isSelected; 
					});
				}
				
				// Compare the two group so that we only updated this.scope.$ctrl.primaryGroups if there is a change. 
				// The reason for this is that on change the select box will emit 'dropdown-select' which is undesirable.
				let groupsMatch = (this.scope.$ctrl.primaryGroups.length == newPrimaryGroups.length) && this.scope.$ctrl.primaryGroups.every((group, index) => group.uuid === newPrimaryGroups[index].uuid );


				if (this.scope.$ctrl.primaryGroupAutoAssign) {
					this.scope.$ctrl.primaryGroups = allGroups.filter( (group:any) => {
						return group.uuid === this.scope.$ctrl.primaryGroupAutoAssign;
					});
				} else if (!groupsMatch) {
					this.scope.$ctrl.primaryGroups = newPrimaryGroups;
				}
			}

			setPrimaryGroupAutoSelection = () => {
				const propName = 'primary-group-auto-select';
				if (this.scope.$ctrl.primaryGroupAutoSelect === undefined) {
					return console.warn(`No attr ${propName} set. This option is ignored.`);
				}

				if (this.options.primaryGroupAutoAssign) {
					this.options.primaryGroupAutoSelection = true;
				}

				this.options.primaryGroupAutoSelection = (this.scope.$ctrl.primaryGroupAutoSelect === 'true')
			};


			/**
			* Hide the selector - should ideally be paired with automatic selection option
			* Also hides the primary group description / section - no references to primary group is required.
			*
			* Warns if set to true, but:
			* - no primary-group-auto-select
			* - nor primary-group-auto-assign
			*/
			setPrimaryGroupHideSelection = () => {
				const propName = 'primary-group-hide-selection';
				if (this.scope.$ctrl.primaryGroupHideSelection === undefined) {
					return console.warn(`No attr ${propName} set. This option is ignored.`);
				}

				this.options.primaryGroupHideSelection = (this.scope.$ctrl.primaryGroupHideSelection === 'true');

				if (!this.isPrimaryGroupSelectionPossible()) {
					return console.error(`Configuration error! Attr ${propName} set to true, but no auto assignment possible!`);
				}
			};

			/**
			* Auto assignment works with one group only. Overwrites any other settings.
			* Auto assigned group needs to exist in the data return from API.
			*/
			setPrimaryGroupAutoAssign = (allAvailableGroups: Array<object>) => {
				const propName = 'primary-group-auto-assign';
				if (this.scope.$ctrl.primaryGroupAutoAssign === undefined) {
					return console.warn(`No attr ${propName} set. This option is ignored.`);
				}

				if (this.scope.$ctrl.primaryGroupAutoAssign) {
					/* Allow only one value, take the first from the primaryGroupAutoAssign collection. */
					const primaryGroupUuid: string = this._getGroupTypesFromString(this.scope.$ctrl.primaryGroupAutoAssign)[0];

					allAvailableGroups.forEach((group: IGroup) => {
						if(group.uuid == primaryGroupUuid) {
							group.isSelected = true;
							this.scope.$ctrl.primaryGroups = group.uuid;
						}
					});


					/* Set auto selection as well, ignore previous settings */
					this.options.primaryGroupAutoSelection = true;
				}
			}

			/**
			* Do we need deeper checks, e.g. It is fine to have auto select but
			* does the supporting data actually exist as well?
			*/
			isPrimaryGroupSelectionPossible = () => {
				if (!this.options.primaryGroupHideSelection || this.scope.primaryGroupAutoSelect) {
					return true;
				}

				return this.options.primaryGroupHideSelection &&
					(this.options.primaryGroupAutoAssign || this.options.primaryGroupAutoSelections);
			};

			getGroupsCollectionByGroupType = (availableGroups)  => {
				let selectableGroups = {};
				availableGroups.forEach(group => {
					if (!selectableGroups[group.type]) {
						selectableGroups[group.type] = [];
					}
					selectableGroups[group.type].push(group);
				});
				return selectableGroups;
			};

			/* Helper functions */

			/**
			 * Check if group type has only one group option?
			 * @param {string} groupType - Name of the group using the name from the API
			 * @return {boolean} - Whether this group type contains only one group
			 */
			hasSingleGroup = (groupType: string) => this.scope.$ctrl.selectableGroups[groupType].length === 1;


			/**
			 * Check if group type is specfied to require a <select>
			 * @param {string} groupType
			 * @return {boolean}
			 */
			requireSingleSelection = (groupType: string) => {
				return this.options.groupTypesForceSingleSelection.indexOf(groupType.toLowerCase()) !== -1;
			}

			/**
			*  Method for use in template when calling the `field-select` directive to set if
			*  option can be deselected or not. Checks the group type:
			*		- is specified on 'select-if-single' attribute and
			*		- contains a single group
			* @param {string} groupType
			* @return {boolean}
			*/
			hasDefaultAndSingleGroup = (groupType: string) => {
				return this.hasSingleGroup(groupType) &&
								this.requireSingleSelection(groupType) &&
								this.options.groupTypesAutoSelectIfSingle.indexOf(groupType.toLowerCase()) !== -1;
			}

			/**
			* Adds group to the selection. Broadcasts to the registration controller.
			*/
			addGroupToSelection() {
				this.setPrimaryGroupCandidates();

				this.rootScope.$broadcast('selection-made', {
					primaryGroup: this.scope.primaryGroup,
					selectableGroups: this._getAvailableGroupsCollection(this.scope.$ctrl.selectableGroups)
				});
			};

		/**
		* Prepare (flatten) data object to collection expected by registration controller.
		* @param {dataObject} Data object containing groups by group type.
		* @return {Array}
		*/
			private _getAvailableGroupsCollection(dataObject: {}) {
				let collection = [];
				Object.keys(dataObject).forEach((type: string) => {
					if (Array.isArray(dataObject[type])) {
						dataObject[type].foreach((item: IGroup) => collection.push(item));
					}
				});

				return collection;
			}
	}]
	};

angular
	.module('canopyRegistrationGroupSelectorModule', [])
	.component('canopyRegistrationGroupComponent', canopyRegistrationComponent )
