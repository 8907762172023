canopyPublic.directive("canopyRegistrationGroupSelector", function () {
	return {
		restrict: "A",
		controller: function ($scope) {
			var onGroupsUpdated = function () {
				var groupTypes = $scope.groupTypes.toLowerCase().replace(/\s/g, "").split(",");
			
				$scope.selectableGroups = $scope.groups.filter(function (group) {
					return groupTypes.indexOf(group.type.toLowerCase()) >= 0;
				});
			};
			
			$scope.$watch("groups", function (groups) {
				if (groups) {
					onGroupsUpdated();
				}
			});
		},
		templateUrl: Luma.paths.context + "/system/mantle/components/canopyPublic/directives/registration-group-selector/canopy-registration-group-selector.template.html",
		scope: {
			groups: "=",
			groupTypes: "@",
			callbacks: "=",
		}
	};
});


/*
canopyPublic.directive("canopyRegistrationGroupSelector", function () {
	return {
		restrict: "A",
		controller: function ($scope) {
			$scope.selection = {
				groups: [],
				primary: null
			};
			
			var onGroupsChanged = function () {
				if ($scope.groups == undefined) {
					return;
				}
				
				var groupsHidden     = $scope.groupsHidden     ? $scope.groupsHidden.toLowerCase().replace(/\s/g, "").split(",") : [];
				var groupsAutoSelect = $scope.groupsAutoSelect ? $scope.groupsAutoSelect.toLowerCase().replace(/\s/g, "").split(",") : [];
				
				Object.keys($scope.groups).forEach(function (key) {
					var groups = $scope.groups[key];
					
					groups.forEach(function (group) {
						group.isSelected = groupsAutoSelect.indexOf(group.type.toLowerCase()) >= 0;
					});
				});
				
				onGroupSelectionChanged();
			};
			
			var onGroupSelectionChanged = function () {
				$scope.selection.groups = [];
				
				Object.keys($scope.groups).forEach(function (key) {
					var groups = $scope.groups[key];
					
					var selected = groups.filter(function (group) {
						return group.isSelected;
					});
					
					$scope.selection.groups = $scope.selection.groups.concat(selected);
				});
				
				if ($scope.selection.primary && $scope.selection.primary.isSelected == false) {
					$scope.selection.primary = null;
				}
			};
			
			$scope.onGroupSelectionToggled = function (group) {
				onGroupSelectionChanged();
			};
			
			$scope.isGroupTypeHidden = function (type) {
				var groupsHidden = $scope.groupsHidden ? $scope.groupsHidden.toLowerCase().replace(/\s/g, "").split(",") : [];
				
				return groupsHidden.indexOf(type) >= 0;
			};
			
			$scope.$watch("groups", function (groups) {
				if (groups) {
					onGroupsChanged();
				}
			});
			
			$scope.$watch("selection.primary", function (primary) {
				Object.keys($scope.groups).forEach(function (key) {
					var groups = $scope.groups[key];
					
					groups.forEach(function (group) {
						group.isPrimary = group == primary;
					});
				});
			});
		},
		templateUrl: Luma.paths.context + "/system/mantle/components/canopyPublic/directives/registration-group-selector/canopy-registration-group-selector.template.html",
		scope: {
			groups: "=",
			callbacks: "=",
			groupsHidden: "@",
			groupsAutoSelect: "@",
			groupTypeAliases: "="
		}
	}
});
*/