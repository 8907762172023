canopyPublic.controller("ResetController", ["$scope", "$stateParams", "$state", "LumaPublicAPI", "canopyModals","utilities", function ($scope, $stateParams, $state, LumaPublicAPI, canopyModals, utilities) {
	var init = function () {
		$scope.reset = {
			token: "",
			password: "",
		};

		$scope.ui = {
			confirmPassword: "",
			passwordMismatch: false,
			passwordMismatchErrorMessage: "Passwords does not match!"
		};

		if ($stateParams.token && $stateParams.code === "UserPasswordExpired") {
			$scope.reset.token = atob($stateParams.token);
			$scope.passwordExpired = true;
		} else if ($stateParams.token) {
			$scope.reset.token = $stateParams.token;
		}
	};
	
	$scope.onSubmit = function () {
		$scope.$broadcast("submit");
	};
	
	$scope.submitReset = function () {
		var onSuccess = function (response) {
			var modal = canopyModals.definitions.message({
				header: "Password reset",
				body: "Your password has been reset. You can now login using your new password.",
				confirm: "Return to login"
			});
			
			canopyModals.instantiate(modal).result.then( function () {
				$state.go("public.login");
			});
		};
		
		var onError = function (response) {
			if (utilities.getPropVal(response, "data.reasons.0.key") === "password") {
				$scope.ui.validationErrorMessage = response.data.reasons[0].value;
			} else {
				var modal = canopyModals.definitions.serverError(response.data);
				canopyModals.instantiate(modal);
			} 
		};
		if ($scope.reset.password === $scope.ui.confirmPassword) {
			$scope.ui.passwordMismatch = false;
			LumaPublicAPI.resetUserPassword($scope.reset).then(onSuccess, onError);
		} else {
			$scope.ui.passwordMismatch = true;
		}
	};
		
	init();
}]);