canopyPublic.controller("LoginController", ["$scope", "$location", "LumaPublicAPI", "$state", "utilities","$timeout", function ($scope, $location, LumaPublicAPI, $state, utilities, $timeout) {
	$scope.controller = {
		loginState: {
			login: "",
			password: "",
			agreedTerms: false,
			isSubmitting: false,
			error: null
		},
		canLogin: false,
		sso: {
			providers: [],
			state: null
		}
	};

	if (Luma.samlResponse && Luma.samlResponse["saml.error"]) {
		if (Luma.samlResponse["saml.error"]["com.intrepia.luma.sso.error.code"]) {
			$state.go("public.denied.other", { 
				code: Luma.samlResponse["saml.error"]["com.intrepia.luma.sso.error.code"] || "UnknownSamlError", 
				message: Luma.samlResponse["saml.error"]["com.intrepia.luma.sso.error.message"] || "No further info is available" 
			});
		}
	}

	window.addEventListener("UnexpectedDeniedStateEncounteredEvent", function (code, message) {
		// eslint-disable-next-line no-console
		console.log("LoginController.js: UnexpectedDeniedStateEncounteredEvent: event: ", event);

		$state.go("public.denied.other", { 
			code: code || "UnexpectedDeniedStateEncounteredEvent", 
			message: message || "No further info is available" 
		});
	});
	
	var initSSO = function () {
		var onSuccess = function (response) {
			$scope.controller.sso.providers = response.data;
			
			$scope.controller.sso.providers.forEach(function (provider) {
				provider.reference = provider.name.replace(/ /g, "_").toLowerCase();
			});
		};
		
		var path = $location.path();
		
		if (path !== "/" && path !== "") {
			$scope.controller.sso.state = btoa(path);
		}
		
		LumaPublicAPI.getSSOProviders().then(onSuccess);
	};
	
	$scope.onSSOProviderSelected = function (provider) {
		LumaPublicAPI.initiateSSO(provider, $scope.controller.sso.state || null);
	};
	
	initSSO();
	
	$scope.$watch("controller.loginState", function (loginState) {
		$scope.controller.canLogin = loginState.login && loginState.password;
	}, true);

	$timeout( function () {
		const fieldLoginAutofill = document.querySelectorAll(".field-login input:-webkit-autofill").length;
		const fieldPasswordAutofill = document.querySelectorAll(".field-password input:-webkit-autofill").length;
		if (!$scope.controller.canLogin && fieldLoginAutofill && fieldPasswordAutofill) {
			$scope.controller.canLogin = true;
		}
	}, 1000);
			
	$scope.submitLogin = function () {
		$scope.controller.loginState.error = null;
		$scope.controller.loginState.isSubmitting = true;
		
		window.setTimeout(function () {
			var data = $scope.controller.loginState;
		
			var onSuccess = function (response) {
				var path = window.location.pathname;
				
				if (path === Luma.paths.context + "/" || path === Luma.paths.context + "/resolve/site/service/login.jsp") {
					window.location = response.data;
				}
				else {
					window.location.reload();
				}
			};
		
			var onError = function (response) {

				if (utilities.getPropVal(response, "data.code") === "UserPasswordExpired") {
					var token = btoa(utilities.getPropVal(response, "data.reasons.0.metadata.reset-token"));
					$state.go("public.reset", {token:token, code:response.data.code});
				}

				$scope.controller.loginState.isSubmitting = false;
			
				if (response.data.reasons) {
					$scope.controller.loginState.error = {
						key: response.data.reasons[0].key,
						value: response.data.reasons[0].value.replace(/\./g, "\n")
					};
				}
			};
		
			LumaPublicAPI.login(data).then(onSuccess, onError);
		}, 500);
	};
}]);