canopyPublic.controller("DepotController", ["$scope", "LumaPublicAPI", "$stateParams", function ($scope, LumaPublicAPI, $stateParams) {
	var init = function () {
		$scope.share = null;
		$scope.error = null;
		$scope.paths = Luma.paths;
		$scope.guestUUID = $stateParams.guestUUID;
		
		if ($stateParams && $stateParams.guestUUID) {
			$scope.guestUUID = $stateParams.guestUUID;
			loadShare();
		}
		else {
			$scope.error = "No share ID specified";
		}
	};

	var loadShare = function () {
		LumaPublicAPI.getShareStatus($stateParams.guestUUID).then(function (successResponse) {
			if (successResponse.data) {
				if (successResponse.data.status === "Available") {
					$scope.share = successResponse.data;
					loadShareAssets();
				} else {
					switch (successResponse.data.status) {
						case "Unfound":
							$scope.error = "This share was not found";
							break;

						default:
							$scope.error = "An unexpected error occurred";
					}
				}
			}
		}, function (errorResponse) {
			$scope.error = "An unexpected error occurred";
		});
	};

	var loadShareAssets = function () {
		var onSuccess = function (successResponse) {
			$scope.share.assets = successResponse.data;
		};

		var onError = function (errorResponse) {
			$scope.error = "An unexpected error occurred";
		};

		LumaPublicAPI.listPublicShareAssets($stateParams.guestUUID).then(onSuccess, onError);
	};
	
	$scope.onGetDownloadAllOptions = function () {				
		LumaPublicAPI.getShareDerivativeMenu($scope.guestUUID).then(function (response) {
			if (response.data.length) {
				$scope.downloadAllOptions = response.data;
			} else {
				window.location.href = $scope.paths.context + "/servlet/download/com.intrepia.luma.ShrinkWrapShare?guestUUID=" + $scope.guestUUID;
			}
		});
	};
	$scope.onDownloadAll = function (option) {
		window.location.href =
			$scope.paths.context +
			"/servlet/interface/com.intrepia.luma.ShrinkWrapShareDerivatives" +
			"?guestUUID=" + $scope.guestUUID +
			"&transformIDs=" + option.id.toString();
	};
	
	$scope.onGetDownloadAssetOptions = function (asset) {
		LumaPublicAPI.getPublicShareAssetDerivativeMenu($scope.guestUUID, [ asset.id ].toString()).then(function (response) {
			if (response.data.length) {
				asset.downloadOptions = response.data;
			} else {
				window.location.href = $scope.paths.context + "/servlet/download/com.intrepia.luma.DownloadSharedAsset?assetID=" + asset.id + "&guestUUID=" + $scope.guestUUID;
			}
		});
	};

	$scope.onDownloadAsset = function (asset, transform) {
		window.location.href =
			$scope.paths.context +
			"/servlet/interface/com.intrepia.luma.ShrinkWrapSharedAssetDerivatives" +
			"?assetIDs=" + asset.id.toString() +
			"&guestUUID=" + $scope.guestUUID +
			"&transformIDs=" + transform.id.toString();
	};

	init();
}]);