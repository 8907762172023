canopyPublic.factory("LumaPublicAPI", ["$http", "$window", function ($http, $window) {
	var lumaPaths = {
		serviceURI: Luma.paths.context + "/servlet/interface/com.intrepia.luma"
	};
	
	var login = function (data) {
		return $http({
			url: lumaPaths.serviceURI + ".Login",
			method: "POST",
			data: $.param({
				login: data.login,
				password: data.password
			}),
			headers: {"Content-Type": "application/x-www-form-urlencoded"}
		});
	};
	
	var register = function (registration) {
		var data = {
			domainID: registration.domainID,
			email: registration.email,
			password: registration.password,
			forename: registration.forename,
			surname: registration.surname,
			company: registration.company,
			branch: registration.branch,
			division: registration.division,
			capacity: registration.capacity,
			position: registration.position,			
			number: registration.number,
			street: registration.street,
			line2: registration.line2,
			line3: registration.line3,
			town: registration.town,
			city: registration.city,
			country: registration.country,
			postcode: registration.postcode,
			phone: registration.phone,
			mobile: registration.mobile,
			label: registration.label,
			groupIDs: registration.groupIDs instanceof Array ? registration.groupIDs.toString() : registration.groupIDs
		};
		
		if (registration.primaryGroupID) {
			data.primaryGroupID = registration.primaryGroupID;
		}
		
		for (var key in data) {
			if (data[key] === undefined) {
				delete data[key];
			}
		}
		
		return $http({
			url: lumaPaths.serviceURI + ".RegisterUser",
			method: "POST",
			data: $.param(data),
			headers: {"Content-Type": "application/x-www-form-urlencoded"}
		});
	};
	
	var requestUserPasswordReset = function (data) {
		return $http({
			url: lumaPaths.serviceURI + ".RequestUserPasswordReset",
			method: "POST",
			data: $.param({
				email: data.email
			}),
			headers: {"Content-Type": "application/x-www-form-urlencoded"}
		});
	};
	
	var resetUserPassword = function (data) {
		return $http({
			url: lumaPaths.serviceURI + ".ResetUserPassword",
			method: "POST",
			data: $.param({
				token: data.token,
				password: data.password
			}),
			headers: {"Content-Type": "application/x-www-form-urlencoded"}
		});
	};
	
	var confirmRegistration = function (data) {
		return $http({
			url: lumaPaths.serviceURI + ".ConfirmRegistration",
			method: "POST",
			data: $.param({
				uuid: data.uuid
			}),
			headers: {"Content-Type": "application/x-www-form-urlencoded"}
		});
	};
	
	var listRegistrationGroups = function (domain) {
		return $http({
			url: lumaPaths.serviceURI + ".ListRegistrationGroups",
			method: "POST",
			data: $.param({
				domainID: domain.id || domain,
				inherit: true
			}),
			headers: {"Content-Type": "application/x-www-form-urlencoded"}
		});
	};
	
	var getShareStatus = function (guestUUID) {
		return $http({
			url: lumaPaths.serviceURI + ".GetShareStatus",
			method: "POST",
			data: $.param({
				guestUUID: guestUUID
			}),
			headers: {"Content-Type": "application/x-www-form-urlencoded"}
		});
	};
	
	var listPublicShareAssets = function (guestUUID) {
		return $http({
			url: lumaPaths.serviceURI + ".ListPublicShareAssets",
			method: "POST",
			data: $.param({
				guestUUID: guestUUID,
				tree: null
			}),
			headers: {"Content-Type": "application/x-www-form-urlencoded"}
		});
	};
	
	var getShareDerivativeMenu = function (guestUUID, assetIDs) {
		let params = {
			guestUUID: guestUUID,
			intents: "Download",
			"listing-trace": true
		};
		if (assetIDs) params.assetIDs = assetIDs;
		return $http({
			url: lumaPaths.serviceURI + ".GetShareDerivativeMenu",
			method: "POST",
			data: $.param(params),
			headers: {"Content-Type": "application/x-www-form-urlencoded"}
		});
	};
	
	var getSSOProviders = function (guestUUID) {
		return $http({
			url: lumaPaths.serviceURI + ".sso.GetProviders",
			method: "POST",
			data: $.param({
			}),
			headers: {"Content-Type": "application/x-www-form-urlencoded"}
		});
	};
	
	var initiateSSO = function (provider, state) {
		var uuid = provider.uuid || provider;
		
		if (uuid) {
			var url  = Luma.paths.context + "/servlet/interface/com.intrepia.luma.sso.Initiate?uuid=" + uuid;
		
			if (state) {
				url = url + "&state=" + state;
			}
		
			$window.location.href = url;
		}
	};
	
	var listRegistrationDomains = function () {
		return $http({
			url: lumaPaths.serviceURI + ".ListRegistrationDomains",
			method: "POST",
			data: $.param({
			}),
			headers: {"Content-Type": "application/x-www-form-urlencoded"}
		});
	};
	
	var listRegistrationDomainTree = function () {
		return $http({
			url: lumaPaths.serviceURI + ".ListRegistrationDomainTree",
			method: "POST",
			data: $.param({
			}),
			headers: {"Content-Type": "application/x-www-form-urlencoded"}
		});
	};
	
	return {
		login: function (data) {
			return login(data);
		},
		register: function (registration) {
			return register(registration);
		},
		requestUserPasswordReset: function (data) {
			return requestUserPasswordReset(data);
		},
		resetUserPassword: function (data) {
			return resetUserPassword(data);
		},
		confirmRegistration: function (data) {
			return confirmRegistration(data);
		},
		listRegistrationGroups: function (domain) {
			return listRegistrationGroups(domain);
		},
		getShareStatus: function (guestUUID) {
			return getShareStatus(guestUUID);
		},
		listPublicShareAssets: function (guestUUID) {
			return listPublicShareAssets(guestUUID);
		},
		getPublicShareAssetDerivativeMenu: function (guestUUID, assetIDs) {
			return getShareDerivativeMenu(guestUUID, assetIDs);
		},
		getShareDerivativeMenu: function (guestUUID) {
			return getShareDerivativeMenu(guestUUID);
		},
		getSSOProviders: function () {
			return getSSOProviders();
		},
		initiateSSO: function (provider, state) {
			return initiateSSO(provider, state);
		},
		listRegistrationDomains: listRegistrationDomains,
		listRegistrationDomainTree: listRegistrationDomainTree
	};
}]);
